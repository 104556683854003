<template>
  <div class="guide-cnt17">
    <h6 class="h6">给我们提建议</h6>
    <div class="cnt">
      <div class="select">
        <el-select v-model="value" placeholder="请选择疑问类型" >
          <el-option
            v-for="item,index in options"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="textarea">
        <p>您的问题与建议</p>
        <textarea placeholder="谢谢您的宝贵建议，我们将不断改进"></textarea>
      </div>
      <div class="text">
        <p>您的联系方式</p>
        <input type="text" placeholder="选填邮箱或者商城账户">
      </div>
      <div class="code">
        <input type="number"><getcodebtn :initPhone='phone' :types='3' @exportErr='phoneError' @exportpass='phonepass' class='btn'></getcodebtn>
      </div>
      <div class="btm">
        <button>提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import getcodebtn from '@/components/getcodebtn/getcodebtn.vue';//获取验证码按钮组件-types属性切换接口
export default {
  name: 'guide-cnt17',
  props: {
    msg: String
  },
  components: {
    getcodebtn,
  },
  data(){
    return {
      options: [{
        value: '商品质量',
        label: '商品质量'
      }, {
        value: '商品缺货',
        label: '商品缺货'
      }, {
        value: '物流发货',
        label: '物流发货'
      }, {
        value: '售后服务',
        label: '售后服务'
      }, {
        value: '网站问题',
        label: '网站问题'
      }, {
        value: '其他问题',
        label: '其他问题'
      }],
      value:'',
      phone:''
    }
  },
  methods:{
    phoneError(v){
      // 手机号错误
      console.log(v)
    },
    phonepass(){
      // 手机号通过
    },
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt17 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;padding-bottom:30px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .select {
      margin-top:30px;
      .el-select {
        width: 536px;
      }
    }
    .textarea {
      p {
        font-weight: bold;font-size: 14px;margin-top:25px;
      }
      textarea {
        width: 504px;height: 80px;border:1px solid #dedede;margin-top:25px;padding:15px;
        &::placeholder {
          color:#C0C4CC;font-size: 14px;
        }
      }
    }
    .text {
      p {
        font-weight: bold;font-size: 14px;margin-top:25px;
      }
      input {
        width: 519px;height: 30px;border:1px solid #dedede;margin-top:25px;padding-left:15px;
        &::placeholder {
          color:#C0C4CC;font-size: 14px;
        }
      }
    }
    .code {
      zoom:1;
      input {
        width: 155px;height: 30px;border:1px solid #dedede;margin-top:25px;padding-left:15px;float:left;
        &::placeholder {
          color:#C0C4CC;font-size: 14px;
        }
      }
      .getcodebtn {
        margin-top:17px;float:left;margin-left:10px;
      }
      &:after {
        content: "";display: block;clear:both;
      }
    }
    .btm {
      button {
        width: 120px;height: 40px;background: #f24545;line-height: 40px;text-align: center;color:#fff;font-size: 14px;border-radius: 10px;margin-left:220px;margin-top:50px;
      }
    }
  }
}
</style>
